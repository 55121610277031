/* eslint-disable */
<template>
    <interact draggable :dragOption="dragOption" class="resize-drag" :style="style" @dragmove="dragmove" @resizemove="resizemove" :class="{ fullscreen: $store.getters.isFullscreenNossaflex}">
        <div class="window" id="container" :class="{ fullscreen: $store.getters.isFullscreenNossaflex, close: !$store.getters.isShownNossaflex}">
            <div class="top-bar" id="top-bar" v-on:dblclick="$store.commit('toggleFullscreenNossaflex')" :class="$store.getters.activeWindow=='NOSSAFLEX' ? 'top-bar' : 'top-bar-deactivated'">
                <div style="color: white; margin-left: 3px; display: flex; align-items: center;"><img class="icon-image" src="../assets/win95Icons/noss.webp"/>EcoGuide</div>
                <div class="triple-button">
                    <div class="button-hide" v-on:click="minimizeNoss"><span style="height: 2px; width: 6px; background: black; margin-top: 8px; margin-right: 2px;"></span></div>
                    <div class="button-expand" v-on:click="$store.commit('toggleFullscreenNossaflex')"><span style="height: 8px; width: 9px; border-left: black 1px solid; border-right: black 1px solid; border-left: black 1px solid; border-bottom: black 1px solid; border-top: black 2px solid"></span></div>
                    <div style="margin-right: 3px; padding-left: 1px;" class="button-close" v-on:click="closeNoss">×</div>
                </div>
            </div>
            <div class="content">
                <div class="scroll-container" :class="{ expandedScrollContainer: $store.getters.isFullscreenNossaflex }">
                    <div class="hero"></div>
                    <div class="inner-content">
                        <div class="heading"><b>Phase One: UX Specifications Refinement</b></div>
                        <div class="paragraph"> UX Specifications were refined to the design brief to understand the user and their demographics, perspective and motivations to act sustainably. A reimagined UX persona and journey map to represent the target user’s journey and how they would come to use the app as just one example of the context EcoGuide would be used.</div>
                    </div>
                    <img src="../assets/Nossaflex/watch.webp" class="images"/>
                    <div class="subtitle">User Persona</div>
                    <div class="inner-content">
                        <div class="heading"><b>Phase Two: Ideation</b></div>
                        <div class="paragraph">Each team member conducted studies on a range of participants where similar trends across findings were identified. Sharing design briefs aided us in identifying these trends and also refined the scope, problem and objective. We used Miro as a platform to share ideas where our findings were placed on sticky notes which helped us manoeuvre each finding as a singular object. Lines were used to indicate connections between findings. Once we had identified the trends, the findings were grouped together in self-identified categories based on the UX honeycomb framework.</div>
                        <div class="paragraph">We first brainstormed ideas, going through each trend category identified and coming up with as many ideas as possible for each finding category. This approach benefitted from ensuring any an all ideas from the team were put on the board. Our Miro board below has the pain points on yellow sticky notes with columns of sticky notes representing each idea.</div>
                    </div>
                    <img src="../assets/Nossaflex/luce.webp" class="images"/>
                    <div class="subtitle">Confirmed Ideas</div>
                    <div class="inner-content">
                        <div class="heading"><b>Phase Three: Solution Sketches</b></div>
                        <div class="paragraph">We all participated as a team in an in-person collaborative sketching session. The Crazy 8s technique was used where each member would fold a piece of paper four times for it to have 8 panels and each member was given 10 minutes to sketch out their solutions. During the session, our team would go through each of the solutions and sketch out as much as we could. This felt very similar to our brainstorming session. After the 10 minutes were up, each of us would present our sketches and the rest of us would provide feedback and comments for each sketch. Annotations were made on the sketches where there was relevant feedback and comment. The initial rough sketches were refined in order to provide a clearer reference for prototype development, as well as to receive feedback from the EcoGuide team.</div>
                        <div class="paragraph">After going through all the solutions, a final sketch session was conducted to sketch out the overall UI layout of the app. This was done to help us know how the app would overall look like since our solutions were very specific to features. Finally, the session ended with discussions and votes of which solution sketch(es) to follow when prototyping each of the ideas. Many of our solutions ended up with refined amalgamations of sketches from different members. Before proceeding to the prototyping phase, Christine iterated on these confirmed sketches by refining them in order to provide a clearer reference for the prototype development as well as to receive more accurate feedback from the EcoGuide team, if any.</div>
                    </div>
                    <img src="../assets/Nossaflex/sketches.webp" class="images"/>
                    <div class="subtitle">Sketches</div>
                    <div class="inner-content">
                        <div class="heading"><b>Phase Four: Initial mid-fi prototyping</b></div>
                        <div class="paragraph">We developed the initial mid-fi prototype that was used as a basis for the overall design of the prototype. The mid-fi prototype was presented to the EcoGuide team for feedback on the solution ideas. No comments on changes were made, but positive remarks were noted on certain solutions, such as the focus on Petit Chou.</div>
                    </div>
                    <div class="inner-content">
                        <div class="heading"><b>Prototype: Onbroading and setup</b></div>
                        <div class="paragraph">We have created a short onboarding process based on the initial sketch designs that welcomes the user and establishes a few ideas with a new user when they first download the application.</div>
                        <div class="paragraph">Onboarding & Setup: <a href="https://xd.adobe.com/view/39d9bdb1-d0ce-4048-8051-8c177882a2fa-6c8a/">here</a></div>
                    </div>
                    <img src="../assets/Nossaflex/onboard.webp" class="images"/>
                    <div class="subtitle">Onbroading</div>
                    <div class="inner-content">
                        <div class="heading"><b>Prototype: Onbroading and setup</b></div>
                        <div class="paragraph">We have created a short onboarding process based on the initial sketch designs that welcomes the user and establishes a few ideas with a new user when they first download the application.</div>
                        <div class="paragraph">Onboarding & Setup: <a href="https://xd.adobe.com/view/39d9bdb1-d0ce-4048-8051-8c177882a2fa-6c8a/">here</a></div>
                    </div>
                    <img src="../assets/Nossaflex/onboard.webp" class="images"/>
                    <div class="subtitle">Onbroading</div>
                    <div class="inner-content">
                        <div class="heading"><b>Prototype: Interactive Tutorial</b></div>
                        <div class="paragraph">We have created a short onboarding process based on the initial sketch designs that welcomes the user and establishes a few ideas with a new user when they first download the application.</div>
                        <div class="paragraph">Interactive Tutorial: <a href="https://xd.adobe.com/view/6774c51e-1076-48f2-91c3-5b26696138a6-3227/">here</a></div>
                    </div>
                    <img src="../assets/Nossaflex/onboard.webp" class="images"/>
                    <div class="subtitle">Interactive Tutorial</div>
                    <div class="inner-content">
                        <div class="heading"><b>Prototype: Tips & Quizzes</b></div>
                        <div class="paragraph">Combining that problem with a lack of motivation and a vast amount of available information, our target users might not want to seek out information by themselves. Petit Chou can be there to help while they play a role as a sustainability buddy in the app and can give users information based on their preferences through Tips and Quizzes in our design.</div>
                        <div class="paragraph">Tips & Quizzes: <a href="https://xd.adobe.com/view/f75bb3be-0381-4e4e-a078-0db3aa4612f9-a208/">here</a></div>
                    </div>
                    <div class="inner-content">
                        <div class="heading"><b>Prototype: Tracking tasks & reaching goals</b></div>
                        <div class="paragraph">We wanted to focus the largest portion of our design development on the habit building feature of the app. The key function of EcoGuide is to support the creation of sustainable habits in users like Barry who aren’t feeling motivated to do so. Our team had many ideas for habit building in the brainstorming phase and we have designed the following aspects to support users in tracking their sustainability habits and using positive feedback systems to form habits.</div>
                        <div class="paragraph">Tracking tasks & reaching goals: <a href="https://xd.adobe.com/view/e0df17fa-e5cb-442b-8b8d-a2f957232a84-b58c/">here</a></div>
                    </div>
                    <img src="../assets/Nossaflex/task.webp" class="images"/>
                    <div class="subtitle">Tracking tasks & reaching goals</div>
                    <div class="inner-content">
                        <div class="heading"><b>Usability Test</b></div>
                        <div class="paragraph">Our key goal in UX evaluations was to assess the usability, desirability and accessibility aspects of our solution designs based on the UX honeycomb framework.</div>
                        <div class="paragraph">The Usability testing was done using our AdobeXD prototype and presented to the participants on a mobile device to provide the most accurate look and feel of the application as possible. A short explanation of the test was done to make sure our participants were comfortable, aware notes were been taken and requested to speak their thoughts aloud.</div>
                    </div>
                    <br>
                </div>
            </div>
            <!-- <div class="resizer resizer-b"></div>
                                <div class="resizer resizer-l"></div>
                                <div class="resizer resizer-t"></div>
                                <div class="resizer resizer-r"></div> -->
        </div>
    </interact>
</template>

<style scoped>
.resize-drag {
    box-sizing: border-box;
    background: none;
    user-select: auto;
    -ms-touch-action: auto;
    touch-action: auto;
    min-height: 40px;
    min-width: 350px;
    position: sticky;
    cursor: auto !important;
}

.window {
    min-height: 500px;
    min-width: 350px;
    height: 500px;
    width: 600px;
    background: rgb(195, 195, 195);
    overflow: hidden;
    border-top: solid rgb(250, 250, 250) 2px;
    border-left: solid rgb(250, 250, 250) 2px;
    border-right: solid rgb(90, 90, 90) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    box-shadow: 1.5px 1.5px black;
    max-height: 100%;
    border-radius: 0.5px;
    max-width: 100%;
    align-items: flex-end;
    outline: rgb(222, 222, 222) 1px solid;
}

.button-expand, .button-close, .button-hide {
    background: rgb(195, 195, 195);
    border-top: solid rgb(250, 250, 250) 1px;
    border-left: solid rgb(250, 250, 250) 1px;
    border-right: solid rgb(90, 90, 90) 1px;
    border-bottom: solid rgb(90, 90, 90) 1px;
    box-shadow: 1px 1px black;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-left: 2px;
}

.button-close:hover, .button-expand:hover, .button-hide:hover {
    cursor: pointer;
}

.button-close:active, .button-expand:active, .button-hide:active {
    border-radius: 0px;
background: rgb(192, 192, 192);
            box-shadow: none;
    border-top: solid rgb(0, 0, 0) 1.5px;
    border-left: solid rgb(0, 0, 0) 1.5px;
    border-bottom: solid rgb(250, 250, 250) 1.5px;
    border-right: solid rgb(250, 250, 250) 1.5px;
}

@media only screen and (max-width: 600px) {
    .window {
        min-width: 50vw;
        width: 90vw;
        max-width: 100vw;
    }
    .scroll-container {
        padding-left: 10vw !important;
        padding-right: 10vw !important;
    }
    .expandedScrollContainer {
        padding-left: 10vw !important;
        padding-right: 10vw !important;
    }
}

.hero {
    width: 100%;
    height: 200px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    border: 1px solid rgb(0, 0, 0, 0.1);
    background-image: url('../assets/Nossaflex/hero_light.webp');
}

.images {
    width: 100%;
    height: 350px ;
    object-fit: cover;
}

.images-full {
    width: 100%;
    height: auto ;
    object-fit: cover;
}

.subtitle {
    font-size: 12px;
    color: gray;
    text-align: center;
}

.heading {
    padding-bottom: 5px;
}

.inner-content {
    padding-top: 30px;
}

.inner-content:last-child {
    padding-bottom: 30px;
}

.fullscreen {
    width: 100% !important;
    height: var(--fullscreen) !important;
    margin: 0;
    transition: all 0.5s ease;
    padding: 0;
}

.nossaflex-app {
    max-width: 64px;
    max-height: 64px;
    border-radius: 22%;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    background-image: url('../assets/Icons/NossaflexLight.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: rgb(0,0,0,0, 0.5) 1px solid;
}

.close {
    display: none;
}

.show {
    display: block;
}

.top-bar {
    display: flex;
    height: 25px;
    width: auto;
    background: rgb(0, 0, 124);
    z-index: 10;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 2px;
}

.top-bar-deactivated {
    background: rgb(123, 125, 123);
}

.triple-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button-red {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #ed6a06;
    display: flex;
    justify-content: center;
    align-items: center
}

.button-yellow {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #f5bf50;
}

.button-green {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #62c454;
}

.button-red:hover {
    cursor: pointer;
}

.button-yellow:hover {
    cursor: pointer;
}

.button-green:hover {
    cursor: pointer;
}

.textarea-content {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    caret-color: black;
    color: black;
}

.bar {
    background: #dadada;
    height: 1px;
    width: 100%;
}

.circle {
    background: #f1f0f2;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
}

.header {
    font-weight: 700;
    font-size: 28px;
    padding-top: 10px;
}

.scroll-container {
    overflow: scroll;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
}

.expandedScrollContainer {
    padding-left: 25vw;
    padding-right: 25vw;
}

.paragraph {
    font-weight: 100;
    font-size: 14px;
    padding-bottom: 10px;
}

.resizer-r {
    cursor: col-resize;
    height: 100%;
    right: 0;
    top: 0;
    width: 5px;
}

.resizer-b {
    bottom: 0;
    cursor: row-resize;
    height: 5px;
    left: 0;
    width: 100%;
}

.resizer-t {
    top: 0;
    cursor: row-resize;
    height: 5px;
    left: 0;
    width: 100%;
}

.resizer-l {
    cursor: col-resize;
    height: 100%;
    left: 0;
    bottom: 0;
    width: 5px;
}

.resizer {
    position: absolute;
}
</style>

<script>
import interact from "interactjs";
export default {
    props: {
        // shownProp: Boolean
    },
    data: function() {
        return {
            resizeOption: {
                edges: { top: true, left: true, bottom: true, right: true },
                
            },
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            },
            dragOption: {
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: "parent",
                        endOnly: true
                    })
                ],
                allowFrom: '.top-bar',
            },
            
            // values for interact.js transformation
            x: 0,
            y: 0,

        }
    },
    computed: {
        style() {
            return {
                height: `${this.h}px`,
                width: `${this.w}px`,
                transform: `translate(${this.x}px, ${this.y}px)`,
                '--fullscreen': window.innerHeight - 40 + "px"
            };
        }
    },
    methods: {
        dragmove(event) {
            this.x += event.dx;
            this.y += event.dy;
            this.$store.commit('zIndexIncrement', 'noss')
        },
        resizemove(event) {
            this.w = event.rect.width;
            this.h = event.rect.height;

            this.x += event.deltaRect.left;
            this.y += event.deltaRect.top;
        },
        dragMouseDown: function(event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function(event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        },
        minimizeNoss(e) {
            e.stopPropagation()
            this.$store.commit('toggleShownNossaflex', false)
            this.$store.commit('changeActiveWindow', 'Finder')
        },
        closeNoss(e) {
            console.log('close')
            e.stopPropagation()
            this.$store.commit('toggleCloseNossaflex', false)
            this.$store.commit('toggleShownNossaflex', false)
        }
    },
    mounted: function() {
        // Query the element
        const ele = document.getElementById('container');

        // The current position of mouse
        let x = 0;
        let y = 0;

        // The dimension of the element
        let w = 0;
        let h = 0;

        const mouseDownHandlerRight = function(e) {
            x = e.clientX;

            const styles = window.getComputedStyle(ele)
            w = parseInt(styles.width, 10);

            document.addEventListener('mousemove', mouseMoveHandlerRight);
            document.addEventListener('mouseup', mouseUpHandlerRight);
        }

        const mouseDownHandlerLeft = function(e) {
            x = e.clientX;

            const styles = window.getComputedStyle(ele)
            w = parseInt(styles.width, 10);

            document.addEventListener('mousemove', mouseMoveHandlerLeft);
            document.addEventListener('mouseup', mouseUpHandlerLeft);
        }

        const mouseDownHandlerUp = function(e) {
            y = e.clientY;

            const styles = window.getComputedStyle(ele)
            h = parseInt(styles.height, 10);

            document.addEventListener('mousemove', mouseMoveHandlerUp);
            document.addEventListener('mouseup', mouseUpHandlerUp);
        }

        const mouseDownHandlerDown = function(e) {
            y = e.clientY;

            const styles = window.getComputedStyle(ele)
            h = parseInt(styles.height, 10);

            document.addEventListener('mousemove', mouseMoveHandlerDown);
            document.addEventListener('mouseup', mouseUpHandlerDown);
        }

        const mouseMoveHandlerUp = function(e) {
            // How far the mouse has been moved
            const dy = e.clientY - y;

            // Adjust the dimension of element
            ele.style.height = `${h - dy}px`;
        };

        const mouseMoveHandlerDown = function(e) {
            // How far the mouse has been moved
            const dy = e.clientY - y;

            // Adjust the dimension of element
            ele.style.height = `${h + dy}px`;
        };

        const mouseMoveHandlerRight = function(e) {
            // How far the mouse has been moved
            const dx = e.clientX - x;

            // Adjust the dimension of element
            ele.style.width = `${w + dx}px`;
        };

        const mouseMoveHandlerLeft = function(e) {
            // How far the mouse has been moved
            const dx = e.clientX - x;

            // Adjust the dimension of element
            ele.style.width = `${w - dx}px`;
        };

        const mouseUpHandlerUp = function() {
            // Remove the handlers of `mousemove` and `mouseup`
            document.removeEventListener('mousemove', mouseMoveHandlerUp);
            document.removeEventListener('mouseup', mouseUpHandlerUp);
        };

        const mouseUpHandlerDown = function() {
            // Remove the handlers of `mousemove` and `mouseup`
            document.removeEventListener('mousemove', mouseMoveHandlerDown);
            document.removeEventListener('mouseup', mouseUpHandlerDown);
        };

        const mouseUpHandlerRight = function() {
            // Remove the handlers of `mousemove` and `mouseup`
            document.removeEventListener('mousemove', mouseMoveHandlerRight);
            document.removeEventListener('mouseup', mouseUpHandlerRight);
        };

        const mouseUpHandlerLeft = function() {
            // Remove the handlers of `mousemove` and `mouseup`
            document.removeEventListener('mousemove', mouseMoveHandlerLeft);
            document.removeEventListener('mouseup', mouseUpHandlerLeft);
        };
        // Query all resizers
        const resizersRight = ele.querySelectorAll('.resizer-r');
        const resizersUp = ele.querySelectorAll('.resizer-t');
        const resizersDown = ele.querySelectorAll('.resizer-b');
        const resizersLeft = ele.querySelectorAll('.resizer-l');

        [].forEach.call(resizersRight, function(resizer) {
            resizer.addEventListener('mousedown', mouseDownHandlerRight);
        });

        [].forEach.call(resizersUp, function(resizer) {
            resizer.addEventListener('mousedown', mouseDownHandlerUp);
        });

        [].forEach.call(resizersDown, function(resizer) {
            resizer.addEventListener('mousedown', mouseDownHandlerDown);
        });

        [].forEach.call(resizersLeft, function(resizer) {
            resizer.addEventListener('mousedown', mouseDownHandlerLeft);
        });
    }
}
</script>